<template>
  <b-form @submit.prevent="onSubmit" @reset.prevent="onReset" id="accAddForm">
    <b-row>
      <b-form-group class="text-justify col-12 px-3">
        <h3>
          {{ $t('acc.add.h') }}
        </h3>
        <i18n path="acc.add.topt" tag="span" class="text-dark">
          <template #mail>
            <b-link :href="`mailto:support@anures.com?subject=`+$t('acc.add.tops')+`%20`+uuid" target="_blank" class="text-primary">{{ $t('acc.add.topm') }}</b-link>
          </template>
        </i18n>
      </b-form-group>
    </b-row>
    <b-row v-if="isUser">
      <b-form-group :label="$t('addr.lfnl')" label-for="accAddFn" class="text-left col-6">
        <b-form-input type="text" id="accAddFn" v-model="f.firstName" :state="valTxt(f.firstName, 2, 32)" class="mt-2" required disabled />
        <b-form-invalid-feedback :state="valTxt(f.firstName, 2, 32)">
          {{ $t('addr.ff') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('addr.lsnl')" label-for="accAddLn" class="text-left col-6">
        <b-form-input type="text" id="accAddLn" v-model="f.lastName" :state="valTxt(f.lastName, 2, 32)" class="mt-2" required disabled />
        <b-form-invalid-feedback :state="valTxt(f.lastName, 2, 32)">
          {{ $t('addr.ff') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-row>
    <b-row v-if="!isUser">
      <b-form-group :label="$t('addr.locnl')" label-for="accAddN" class="text-left col-12">
        <b-form-input type="text" id="accAddN" v-model="f.name" :state="valTxt(f.name, 2, 32)" class="mt-2" required disabled />
        <b-form-invalid-feedback :state="valTxt(f.name, 2, 32)">
          {{ $t('addr.ff') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-row>
    <AddressContent idPrefix="accAdd" @status="getAddrStatus" form="accAddForm" :disabled="!isUser"
        :aCity="f.city" @aCity="f.city = $event"
        :aStreet="f.street" @aStreet="f.street = $event"
        :aNumber="f.number" @aNumber="f.number = $event"
        :aZip="f.zip" @aZip="f.zip = $event"
        :aCountryId="f.countryId" @aCountryId="f.countryId = $event"
        :aStateId="f.stateId" @aStateId="f.stateId = $event" />
    <b-row>
      <b-form-group :label="$t('addr.ltxl') + $t('acc.add.ltxo')" label-for="accAddTx" class="text-left col-6">
        <b-form-input type="text" id="accAddTx" v-model="f.taxNumber" :state="valTax" class="mt-2" :disabled="!isUser" />
        <b-form-invalid-feedback :state="valTax">
          {{ $t('addr.ff') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('addr.lrnl')" label-for="accAddRn" class="text-left col-6" v-if="!isUser">
        <b-form-input type="text" id="accAddRn" v-model="f.registerNumber" :state="valRn" class="mt-2" disabled />
        <b-form-invalid-feedback :state="valRn">
          {{ $t('addr.ff') }}
        </b-form-invalid-feedback>
      </b-form-group> 
    </b-row>

    <b-form-row>
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button type="reset" variant="primary" class="mr-2 mr-sm-3" id="accAddBtnReset" :disabled="!isChanged"><Icon type="r" /> {{ $t('btn.r') }}</b-button>
        <b-button type="submit" variant="primary" id="accAddBtnSubmit" :disabled="(!val && !isUser) || !isChanged"><Icon type="u" /> {{ $t('btn.u') }}</b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import Icon from '@/components/Icon';
import { REST } from '@/components/RestCall';
import AddressContent from '@/components/shared/AddressContent';
import { equalObjects } from '@/components/scripts/EqualObjects';

export default {
  data() {
    return {
      f: {
        uuid4: '',
        firstName: '',
        lastName: '',
        name: '',
        taxNumber: null,
        registerNumber: null,
        street: '',
        number: '',
        city: '',
        zip: '',
        countryId: 1,
        stateId: null,
      },
      isAddrSuccess: false,
    }
  },
  computed: {
    onlineData() {
      return this.$store.state.oAddress;
    },
    uuid() {
      return this.$store.getters.uuid;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    valTax() {
      if (this.isUser) {
        if (this.f.taxNumber === null) return null;
        return this.valTxt(this.f.taxNumber, 2, 50);
      } else {
        return this.valTxt(this.f.taxNumber, 2, 50);
      }
    },
    valRn() {
      if (this.f.registerNumber === null) return null;
      return this.f.registerNumber === '' || this.valTxt(this.f.registerNumber, 2, 100);
    },
    val() {
      if (this.isUser) {
        return (this.valTxt(this.f.firstName, 2, 32) && this.valTxt(this.f.lastName, 2, 32) && this.valTax !== false && this.isAddrSuccess);
      } else {
        return (this.valTxt(this.f.name, 2, 32) && this.valTax !== false && this.valRn && this.isAddrSuccess);
      }
    },
    isChanged() {
      return !equalObjects(this.$store.state.oAddress, this.f);
    },
  },
  watch: {
    onlineData: function(data) {
      this.f = { ...data };
    }
  },
  created() {
    REST.get('/' + (this.isUser ? 'users' : 'customers') + '/' + this.uuid + '/address')
        .then(resp => {
          this.$store.commit('setAddress', resp.d.data);
        }).catch(e => {
          this.$store.commit('showWarn', e.message)
        })
  },
  methods: {
    valTxt(txt, minLen, maxLen) {
      if (txt == null) return null;
      if (typeof txt != 'string') return false;
      return (txt.length >= minLen && txt.length <= maxLen) ? true : false;
    },
    getAddrStatus(state) {
      this.isAddrSuccess = state;
    },
    onSubmit() {
      if (this.val) {
        let data = { ...this.f};
        if (this.isUser) {
          delete data.name;
          delete data.registerNumber;
        } else {
          delete data.firstName;
          delete data.lastName;
        }
        REST.put('/' + (this.isUser ? 'users' : 'customers') + '/'+ this.uuid + '/address', data)
            .then(resp => {
              this.$store.commit('showSuccess', resp.d.message);
              this.$store.commit('setAddress', data); // triggers again computed/watch props
            }).catch(e => {
              this.$store.commit('showWarn', e.message);
            })
      } else {
        this.$router.replace('/').catch(() => {});
      }
    },
    onReset() {
      this.f = { ...this.$store.state.oAddress };
    },
  },
  components: {
    Icon,
    AddressContent,
  }
}
</script>
